import React, { Fragment, useState, useEffect, useContext, useRef } from 'react';
import { AuthContext} from "../../../../context/Auth/AuthProvider";
import {LocationsContext} from "../../../../context/Locations/Locations";
import {SensorsContext} from "../../../../context/Devices/Sensors";
import {TelegramContext} from "../../../../context/Telegrams/Telegrams";
import Swal from "@molline/sweetalert2";
import {NavLink, Link} from "react-router-dom";
import {useRouter} from "../../../../components/Shared/Router/Router";
import OrgCookieCrumb from "../../../../components/Dashboard/orgCookieCrumb";
import MeasurementsTbl from "../../../../components/Dashboard/tables/dataTables/tblSensorMeasurements";
import {GroupsContext} from "../../../../context/Groups/Groups";
import {OrganizationContext} from '../../../../context/Organization/Organization';
import {PermissionsWrapper, hasPermission} from "../../../../components/Dashboard/reactPermissionsWrapper";
import TblComments from "../../../../components/Dashboard/tables/dataTables/tblComments";
import TagComponent from '../../../../components/Dashboard/tagsComponent'
import uniqId from 'uniqid';

import PageTour from "../../../../components/Dashboard/tour/tour";
import TourSteps from "../../../../TourSteps.json"

import de from '../../../../components/Dashboard/tables/datatable.de.json';

const $ = require('jquery')
$.DataTable = require('datatables.net');

const SensorDetails = (props) => {

    
    const router = useRouter()

    const { 
        getSensor, 
        getSensorMeasurements, 
        sensors,
        getAllSensors,
        sensor, 
        setSensor, 
        sensorMeasurements, 
        updateTags, 
        changeSensorsLocation, 
        addComment, 
        deleteComment, 
        addLog, 
        deleteLog, 
        updateSensor,
        medium_icon, 
        sensorFlags,
        sensorTypeIcon 
    } = useContext(SensorsContext);

    const { locations, getAllLocations } = useContext(LocationsContext);
    const { serial_number } = router.query
    const [tabActive, setTabActive] = useState('overview')
    const { group, setGroup, getGroups, groups, setGroups } = useContext(GroupsContext);

    const { getTelegram } = useContext(TelegramContext)
    const { user, getUser } = useContext(AuthContext)

    const [sensorComments, setSensorComments] = useState([])
    const sensorChanged = useCompare(sensor);
    const sensorIdChanged = useCompare(serial_number);
    const measurementsChanged = useCompare(sensorMeasurements);
    const [isEditing, setIsEditing] = useState(false)
    const {organizations, organization} = useContext(OrganizationContext);
    const [editForm, setEditForm] = useState({})

    useEffect(() => {
        
            if (sensorIdChanged ) {
                getSensor(serial_number)
                getSensorMeasurements(serial_number)
            }
    }, [sensors, sensorIdChanged, serial_number, sensor, sensorChanged, sensorMeasurements, measurementsChanged]);

    function useCompare (val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const errorToast = (err) => {
        if(!err) return
        Swal.fire({
            toast: true,
            position: 'bottom-end',
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: true,
            text: err?.response?JSON.stringify(err.response.data):err,
            showConfirmButton: false,
            customClass: {
                popup: "bg-danger text-white"
            }
        })
    }
    
    const capitalize = (str) => {
        if(!str) return str;
        if (str.indexOf("-") > -1)
            return str.split("-").map((w) => `${w.charAt(0).toUpperCase()}${w.slice(1)}`).join("-")
        return `${str.charAt(0).toUpperCase()}${str.slice(1)}`
    }

    const addSensorComment = async (comment) => {
        await addComment({comment: comment, _id: sensor._id})
    }

    const deleteSensorComment = async (comment_id) => {
        await deleteComment({sensor_id: sensor._id, comment_id: comment_id})
    }

    const doYouReallyWantToSave = () => {
        return new Promise(async (resolve) => {
            if (window.sessionStorage && !window.sessionStorage.getItem("yeswecan")) {
                const answer = await Swal.fire({
                    title: 'Sicher?',
                    input: 'checkbox',
                    text: 'Damit werden die Daten gespeichert',
                    inputPlaceholder: 'Nicht mehr fragen',
                    showCancelButton: true,
                    confirmButtonText: 'Ja',
                    cancelButtonText: "Nein",
                })
                if (!answer.isConfirmed)
                    return resolve(false);
                if (answer.value === 1) {
                    window.sessionStorage.setItem("yeswecan", true);
                    return resolve(true);
                }
            }
            return resolve(true);
        })
    }

    const UI = () => {

        const last_transmission = () => {
            if(sensor?.state?.date){
                let date = new Date(sensor.state.date);
                let intlDTF = new Intl.DateTimeFormat('de-DE');
                const iconSpacing = "2.5rem";
                return (
                    <div style={{textAlign:"center", display:"inline-block"}}>
                        <div style={{marginLeft:"-"+iconSpacing}}>
                            <span style={{width:iconSpacing,display:"inline-block"}}><i class="fa-solid fa-calendar-days"></i></span>
                            <span>{ intlDTF.format(date) }</span>
                        </div>
                        <div style={{marginLeft:"-"+iconSpacing}}>
                            <span style={{width:iconSpacing, display:"inline-block"}}><i class="fa-solid fa-clock"></i></span>
                            <span>{ `${date.getHours()}:${date.getMinutes()}`} {(!!sensor?.state?.uid) ? `(${sensor.state.uid})` : ''}</span>
                        </div>
                    </div>
                )
            }else{
                return `--`
            }
        }

        const stateValue = () => {
            if(sensor?.state?.value){
                return (
                    <Fragment>
                        <div className="text-centered">
                            { (+sensor.state.value).toFixed(2) }
                        </div>
                    </Fragment>
                )
            }else{
                return `--`
            }
        }
        
        const addSensorTag = (newTags) => {
            let tags = [...editForm.tags]
            let _newTags = newTags.split(',').map((v) => v.trim());
            for (let newTag of _newTags) {
                tags.push(newTag)
            }
            tags = tags.filter((v, i, a) => a.indexOf(v) === i && v.trim());
            updateForm('tags', tags)
        }

        const deleteSensorTag =  (index) => {
            let tags = [...editForm.tags];
            tags.splice(index, 1)
            updateForm('tags', tags)
        }

        const updateForm = (key, value, nestedKey) => {
            if(key === 'organization_id') {
                const upd = {...editForm}
                upd.location_id = ''
                upd.organization_id = value
                setEditForm(upd)
                return
            }
            if (nestedKey) {
                const nested = editForm[nestedKey]
                nested[key] = value
                setEditForm({ ...editForm, [nestedKey]: nested })
            } else {
                setEditForm({ ...editForm, [key]: value })
            }
        }

        const saveSensorDetails = async () => {
            if(hasPermission({user, scopes:['sensor.update.any', 'sensor.update.own']})) {
                let params = editForm;
                const doSave = await doYouReallyWantToSave()
                if (!doSave) {
                    return;
                }

                try {
                    await updateSensor(params)
                    setIsEditing(false)
                }catch(e) {
                    console.log('error at updating sensor', e);
                    errorToast('Zähler konnte nicht geupdated werden')
                }
            }
        }

        const tryChangeLocation = async () => {
            let selectedLocation = await showChangeLocationModal();
            if (selectedLocation && selectedLocation != "") {
                updateForm('location_id', selectedLocation)
            }
        }

        const initDT = ({ id, data, columns = [], options = {}, force = false }) => {
            //you need to force init if the table is still hidden, e.g. on prerender of swal
            //console.log("trying to initialize datatable", id, $.fn.DataTable.isDataTable(id), data, columns)
            if (!data || !force && data.length <= 0 || $.fn.DataTable.isDataTable(id) || !force && !$(id).is(":visible"))
                return;
            let table = $(id).DataTable({
                "language": de,
                "processing": true,
                "data": data,
                "rowId": "_id",
                "order": [[0, "desc"]],
                "pageLength": 25,
                "columns": columns,
                "drawCallback": function (settings) {
                    $('#tblSelectLocations tbody tr').off('mouseenter').on('mouseenter', function () {
                        $(this).css('cursor', 'pointer');
                        $(this).css('background-color', '#f3f2f7');
                    });

                    $('#tblSelectLocations tbody tr').off('mouseleave').on('mouseleave', function () {
                        $(this).css('background-color', '#fff');
                    });
                },
                ...options
            });
        }

        const showChangeLocationModal = async () => {
            let modalLocations = locations
            if(editForm?.organization_id !== sensor?.organization_id) {
                try {
                    modalLocations = await getAllLocations({organization_id: editForm.organization_id, filter: false}) 
                } catch (e) {
                    console.log('errror at getting locations for location', e);
                    return
                }
            }
            return new Promise((resolve, reject) => {
                const changePopup = Swal.fire({
                    width: "98%",
                    title: `Nutzer-/Mieteinheit suchen`,
                    allowOutsideClick: true,
                    backdrop: true,
                    allowEscapeKey: true,
                    allowEnterKey: false,
                    showConfirmButton: false,
                    showCancelButton: true,
                    cancelButtonText: 'Abbrechen',
                    html:
                        `<table id="tblSelectLocations" className="table mb-0 row-border hover order-column " role="grid" width="100%">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Alias</th>
                                    <th>Straße</th>
                                    <th>Hausnr.</th>
                                    <th>Wohnung</th>
                                    <th>Stadt</th>
                                    <th>PLZ</th>
                                    <th>Land</th>
                                    <th></th>
                                </tr>
                            </thead>
                        </table>`,
                    willOpen: () => {
                        initDT({
                            id: '#tblSelectLocations',
                            data: modalLocations,
                            columns: [
                                { "data": "_id" },
                                {
                                    "data": "nickname",
                                    render: (data) => capitalize(data)
                                },
                                {
                                    "data": "address.street",
                                    render: (data) => capitalize(data)
                                },
                                { "data": "address.house_number" },
                                { "data": "address.street1" },
                                {
                                    "data": "address.city",
                                    render: (data) => capitalize(data)
                                },
                                { "data": "address.post_code" },
                                {
                                    "data": "address.country",
                                    render: (data) => data.toLocaleUpperCase()
                                },
                                {
                                    data: "null", render: function (data, type, row, meta) {
                                        return `<div style="text-align:right"><button class="btn btn-primary btn-small" id="location-select-btn" data-location_id=${row._id}>Auswählen</button></div>`
                                    }
                                },
                            ],
                            options: {
                                rowId: "_id",
                                columnDefs: [{ "defaultContent": "-", "targets": "_all" }],
                                drawCallback: function (settings) { //to make it work on pagination
                                    $('#tblSelectLocations tbody tr').off('mouseenter').on('mouseenter', function () {
                                        $(this).css('cursor', 'pointer');
                                        $(this).css('background-color', '#f3f2f7');
                                    });

                                    $('#tblSelectLocations tbody tr').off('mouseleave').on('mouseleave', function () {
                                        $(this).css('background-color', '#fff');
                                    });

                                    $('#tblSelectLocations tbody tr').off('click').on('click', function () {
                                        const button = $(this).find('button').first();
                                        if (button && button.get(0)) {
                                            let location_id = button.get(0).dataset.location_id;
                                            resolve(location_id);
                                            changePopup.close();
                                        }
                                    });
                                }
                            },
                            force: true
                        })

                    }
                })

            });
        }

        const OverviewTabContent = () => {
            
            const addAttribute = () => {
                Swal.fire({
                    title: 'Neue Attribute hinzufügen (mit Komma separieren)',
                    input: 'text',
                    showCancelButton: true,
                    confirmButtonText: 'Hinzufügen',
                    cancelButtonText: "Abbrechen",
                }).then(async (result) => {
                    if (!result.isConfirmed) return;
                    if (!result.value.trim()) {
                        errorToast("Attribute dürfen nicht leer sein ...");
                        return;
                    }
                    let updatedAttributes = editForm.attributes
                    if(updatedAttributes === undefined) updatedAttributes = []
                    let newAttributes = result.value.split(',').map((v) => v.trim());
                    for (let newAttribute of newAttributes) {
                        updatedAttributes.push(newAttribute)
                    }
                    updateForm('attributes', updatedAttributes)
                })
            }

            const deleteAttribute = (index) => {
                let attributes = [...editForm.attributes];
                attributes.splice(index, 1)
                updateForm('attributes', attributes)
            }

            const RenderConditionalButtons = () => {
                if (isEditing === true) {
                    return (
                        <div className="col-12">
                            <button className="btn btn-primary mt-1 me-1 waves-effect waves-float waves-light" onClick={(e) => { e.preventDefault(); saveSensorDetails() }}>Speichern</button>
                            <button className="btn btn-muted mt-1 me-1 waves-effect waves-float waves-light" onClick={(e) => {
                                e.preventDefault();
                                setIsEditing(false);
                            }}>Abbrechen</button>
                        </div>
                    )
                } else {
                    if (!!user) {
                        return (
                            <PermissionsWrapper
                            user={user}
                            scopes={['gateway.update.any']}
                            >
                                <div className="col-12">
                                    <button id="sensorDetailsUpdateBtn" className="btn btn-secondary mt-1 me-1 waves-effect waves-float waves-light" onClick={(e) => {
                                        e.preventDefault();
                                        setEditForm(sensor)
                                        setIsEditing(true);
                                    }}>Anpassen</button>
                                </div>
                            </PermissionsWrapper>
                        )
                    }
                }
                return (<Fragment></Fragment>);
            }

            const renderAttributes = () => {
                let myAtributes = sensor?.attributes
                if(isEditing) myAtributes = editForm?.attributes
                if(myAtributes === undefined) myAtributes = []

                return (
                    <div className="card-header border-bottom" id="sensordetailsAttributes">
                        <div style={{ display: "inline-flex", alignItems: "center" }}>
                            <button disabled={!isEditing} onClick={() => addAttribute()} className="btn btn-primary btn-sm" style={{ width: "18px", height: "18px", padding: "0", borderRadius: "18px", marginRight: "0.5rem" }}>+</button>
                            <h6 style={{ margin: "0", marginRight: "0.5rem" }}>ATTRIBUTE</h6>
                            {myAtributes.map((v, i) => {
                                return (
                                    <span onClick={isEditing ? () => deleteAttribute(i) : () => { }} style={{ marginRight: "0.5rem", padding: "5px 9px", textTransform: "uppercase", fontSize: "11px" }} key={uniqId()} className="badge rounded-pill bg-secondary">
                                        <span>{v}</span> {isEditing && <i className="fa-solid fa-xmark"style={{ marginTop: "-1px" }} ></i>}
                                    </span>
                                )
                            })}
                        </div>
                    </div>
                )
            }

            return (
                <Fragment>
                    
                    <div className='card'>
                    <div className="card-header border-bottom">
                        <div style={{ display: "flex", flex: "1 1 auto" }}>
                            <div style={{ flex: 1 }}>
                                <h4 className="card-title">Zähler-Details <PageTour steps={TourSteps.sensors.details}  isButton={false} /></h4>
                            </div>
                        </div>
                    </div>
                        {sensor.tags && (sensor.tags.length >= 0 && isEditing || !isEditing && sensor.tags.length > 0) &&
                            <TagComponent tags={isEditing ? editForm.tags : sensor.tags} isEditing={isEditing} addTagFunction={addSensorTag} deleteTagFunction={deleteSensorTag} />
                        }
                        {renderAttributes()}
                        <div className="card-body" id="sensorDetailsData">
                            <form className="validate-form mt-2 pt-50" noValidate="noValidate">
                                <div className="row">
                                    <div className="col-12 col-sm-6 mb-1">
                                        <label className="form-label">Seriennummer</label>
                                        <input type="text" className="form-control" value={sensor.serial_number} disabled={true} />
                                    </div>
                                    <div className="col-12 col-sm-6 mb-1">
                                        <label className="form-label">Token (externe ID)</label>
                                        <input type="text" className="form-control" onChange={(e) => { updateForm('token', e.target.value) }} value={isEditing ? editForm.token : sensor?.token} disabled={!isEditing}  />
                                    </div>
                                    <div className="col-12 col-sm-6 mb-1">
                                        <label className="form-label">AES-Schlüssel</label>
                                        <input type={isEditing ? "text" : "password"} className="form-control" onChange={(e) => { updateForm('symetric_key', e.target.value) }} value={isEditing ? editForm.symetric_key : sensor?.symetric_key} disabled={!isEditing}  />
                                    </div>
                                    <div className="col-12 col-sm-6 mb-1">
                                        <label className="form-label">Faktor</label>
                                        <input type="text" className="form-control" onChange={(e) => { updateForm('factor', e.target.value) }} value={isEditing ? editForm.factor : sensor?.factor} disabled={!isEditing} />
                                    </div>
                                    <div className="col-12 col-sm-6 mb-1">
                                        <label className="form-label">Beschreibung</label>
                                        <input type="text" className="form-control" onChange={(e) => { updateForm('minor_location', e.target.value) }} value={isEditing ? editForm.minor_location : sensor?.minor_location} disabled={!isEditing} />
                                    </div>
                                    <div className="col-12 col-sm-6 mb-1">
                                        <label className="form-label" htmlFor="overviewLocation">Nutzereinheiten</label>
                                        <div className="input-group">
                                            <span className="input-group-text"><i class="fa-solid fa-house"></i></span>
                                            <input type="text" className="form-control" id="overviewLocation" name="location" onClick={tryChangeLocation} 
                                            value={
                                                isEditing ? 
                                                editForm.location_id : 
                                                (sensor.location)? `${capitalize(sensor.location.address.street)} ${sensor.location.address.house_number} ${(sensor.location.address.street1) ? 'ME '+sensor.location.address.street1: ''}`
                                                : sensor.location_id} disabled={!isEditing} />
                                        </div>

                                    </div>
                                    <div className="col-12 col-sm-6 mb-1">
                                        <label className="form-label" htmlFor="organization">Organisation*</label>
                                        <PermissionsWrapper
                                            user={user}
                                            scopes={['sensor.create.any', 'sensor.update.any']}
                                            errorProps={{ disabled: true }}
                                        >
                                            <select className="select form-select" name="organization_id" id="overviewOrganization" onChange={(e) => updateForm('organization_id', e.target.value)} disabled={!isEditing}>
                                                {
                                                    organizations.map(org => {
                                                        return (
                                                            <option key={org._id} value={org._id} selected={isEditing ? editForm?.organization_id === org._id : sensor.organization_id === org._id}>{org.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </PermissionsWrapper>
                                    </div>
                                </div>
                                {RenderConditionalButtons()}
                            </form>
                        </div>
                    </div>
                </Fragment>
            )
        }

        return (
            <Fragment>

                <div className="content-header row">
                    <div className="content-header-left col-md-9 col-12 mb-2">
                        <div className="row breadcrumbs-top">
                            <div className="col-9">
                                <h2 className="content-header-title float-start mb-0">Zähler-Details {(sensor?.serial_number) ? `(${sensor.serial_number})` : ''}</h2>
                                <div className="breadcrumb-wrapper">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a>Apps</a></li>
                                        <li className="breadcrumb-item"><Link className="breadcrumb-navLink" to="/apps/sensors"><a>Zähler</a></Link></li>
                                        <li className="breadcrumb-item active">Detail-Ansicht
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <OrgCookieCrumb />
                </div>
                <div className="content-body">

                    <section id="sensorData">
                        <div className="row match-height">

                            <div className="col-xl-2 col-md-4" id="sensorLastContact">
                                <div className="card text-center">
                                    <div className="card-body">
                                        <div className="badge-circle badge-circle-lg badge-circle-light-success mx-auto my-1">
                                            <i class="fa-solid fa-heart-pulse fa-6x"></i>
                                        </div>
                                        <h2 className="mb-0">{last_transmission()}</h2>
                                        <p className="text-muted mt-1 line-ellipsis">Zuletzt gesendet</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-2 col-md-4" id="sensorLastValue">
                                <div className="card text-center">
                                    <div className="card-body">
                                        <div className="badge-circle badge-circle-lg badge-circle-light-success mx-auto my-1">
                                        <i class="fa-solid fa-calculator fa-6x"></i>
                                        </div>
                                        <h2 className="mb-0" title="Aktueller Wert des Sensors">{ stateValue() }</h2>
                                        <h2 className="mb-0" style={{textTransform:"uppercase"}} title="Messeinheit des Sensors">{(sensor !== undefined && sensor.unit_of_measure !== undefined)?sensor.unit_of_measure:'' }</h2>
                                        <p className="text-muted mt-1 line-ellipsis">Wert</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-2 col-md-4" id="sensorType">
                                <div className="card text-center">
                                    <div className="card-body">
                                        <div className="badge-circle badge-circle-lg badge-circle-light-success mx-auto my-1">
                                            <span dangerouslySetInnerHTML={{__html:medium_icon({sensor, size:6})}}></span>
                                        </div>
                                        <h2 className="mb-0" style={{textTransform:"uppercase"}} title="Sensor-Typ (Mediumcode)">{(sensor !== undefined && sensor.manufacturer_shortcode !== undefined)? `${sensor.manufacturer_shortcode} `:''}{sensor !== undefined ? sensor.medium_code : '' }</h2>
                                        <h2 className="mb-0" style={{textTransform:"uppercase"}}>{sensor !== undefined ? sensor.type : '' }</h2>
                                        <p className="text-muted mt-1 line-ellipsis">Zählertyp</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-2 col-md-4" id="sensorTransferType">
                                <div className="card text-center">
                                    <div className="card-body">
                                        <div className="badge-circle badge-circle-lg badge-circle-light-success mx-auto my-1">
                                            <span dangerouslySetInnerHTML={{__html:sensorTypeIcon({sensor, size:6})}}></span>
                                        </div>
                                        <h2 className="mb-0" style={{textTransform:"uppercase"}}>{(sensor.radio === true)? "Funk":"Verdrahtet"}</h2>
                                        <h2 className="mb-0" style={{textTransform:"uppercase"}} title="RSSI / Signalstärke zum Gateway.">{ (sensor?.state?.rssi && sensor?.radio) ? `R ${sensor.state.rssi}` : ' -' }</h2>
                                        <p className="text-muted mt-1 line-ellipsis">Übertragungstyp</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>

                    <section id="sensorTabs">
                        <div className="row">
                            <div className="col-12">
                                <ul className="nav nav-pills mb-2">

                                    <li className="nav-item">
                                        <a className={`nav-link ${(tabActive === 'overview') ? 'active' : ''}`} onClick={() => { setTabActive('overview') }}>
                                            <i class="fa-solid fa-magnifying-glass"></i>
                                            <span className="fw-bold">Details</span>
                                        </a>
                                    </li>

                                    <li className="nav-item" id="sensorMeasurementListItem">
                                        <a className={`nav-link ${(tabActive === 'measurements')? 'active': ''}`} onClick={ () => {setTabActive('measurements')} }>
                                            <i class="fa-solid fa-gauge"></i>
                                            <span className="fw-bold">Messungen </span>
                                        </a>
                                    </li>

                                    <PermissionsWrapper
                                        user={user}
                                        scopes={['error.view.any']}
                                    >
                                    <li className="nav-item" id="stateReportItem">
                                        <a className={`nav-link ${(tabActive === 'statusreport')? 'active': ''}`} onClick={ () => {setTabActive('statusreport')} }>
                                            <i class="fa-solid fa-gauge"></i>
                                            <span className="fw-bold">Funktionsbericht </span>
                                        </a>
                                    </li>
                                    </PermissionsWrapper>

                                    <PermissionsWrapper
                                        user={user}
                                        scopes={['comment.view.any', 'comment.view.own']}
                                    >
                                        <li className="nav-item" id="sensorCommentListItem">
                                            <a className={`nav-link ${(tabActive === 'comments')? 'active': ''}`} onClick={ () => {setTabActive('comments')} }>
                                                <i class="fa-solid fa-message-lines"></i>
                                                <span className="fw-bold">Anmerkungen</span>
                                            </a>
                                        </li>
                                    </PermissionsWrapper>

                                    {/*
                                    <li className="nav-item">
                                        <a className={`nav-link ${(tabActive === 'maintenance')? 'active': ''}`} onClick={ () => {setTabActive('maintenance')} }>
                                        <FontAwesomeIcon icon={faRibbon} />
                                        <span className="fw-bold">Wartung</span>
                                        </a>
                                    </li>

                                    <li className="nav-item">
                                        <a className={`nav-link ${(tabActive === 'administration')? 'active': ''}`} onClick={ () => {setTabActive('administration')} }>
                                        <FontAwesomeIcon icon={faWrench} />
                                        <span className="fw-bold">Administration</span>
                                        </a>
                                    </li> */}

                                </ul>

                            </div>
                        </div>
                    </section>

                    <section id="tabpanels">
                        <div className="row">
                            <div className="col-12">
                                <div className="tab-content pt-1">

                                    <div className={`tab-pane ${(tabActive === 'overview') ? 'active' : ''}`} id="overview" role="tabpanel" aria-labelledby="home-tab-fill">
                                        {OverviewTabContent()}
                                    </div>

                                    <div className={`tab-pane ${(tabActive === 'measurements')? 'active': ''}`}  id="measurements" role="tabpanel" aria-labelledby="home-tab-fill">
                                        <div className="card">
                                            <div className="card-header border-bottom">
                                                <h4 className="card-title">Messungen <PageTour steps={TourSteps.sensors.details}  isButton={false} /></h4>
                                            </div>
                                            <div className="card-body py-2 my-25" id="measurementTbl">

                                                <MeasurementsTbl />

                                            </div>
                                        </div>
                                    </div>

                                    <div className={`tab-pane ${(tabActive === 'statusreport')? 'active': ''}`}  id="measurements" role="tabpanel" aria-labelledby="home-tab-fill">
                                        <div className="card">
                                            <div className="card-header border-bottom">
                                                <h4 className="card-title">Status des Sensors</h4>
                                            </div>
                                            <div className="card-body py-2 my-25" id="measurementTbl">

                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Beschreibung</th>
                                                            <th scope="col" className='text-center'>Status</th>
                                                            <th scope="col" className='text-center'>Handlung erforderlich</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {(!!sensor?.state?.report && sensor?.state?.report?.length !== 0) ? 
                                                        
                                                        Object.entries(sensor.state.report).map(([key, value]) => (
                                                            <tr className={`${(value.is_error) ? 'table-danger': ''}`}>
                                                                <td>{value.description}</td>
                                                                <td className='text-center'>{(value.value === true || value.value === false) ? ((value.value) ? "ja" : "nein") : value.value}</td>
                                                                <td className='text-center'>{(value.is_error) ? "ja" : "nein"}</td>
                                                            </tr>
                                                        ))
                                                        
                                                        : 
                                                            <tr>
                                                                <td className='text-center' colSpan={3}>Kein Bericht vorhanden</td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </table>

                                            </div>
                                        </div>
                                    </div>

                                    <PermissionsWrapper
                                        user={user}
                                        scopes={['comment.view.any', 'comment.view.own']}
                                    >
                                        <div className={`tab-pane ${(tabActive === 'comments')? 'active': ''}`} id="comments" role="tabpanel" aria-labelledby="home-tab-fill">
                                            <TblComments comments={sensor.comments} addComment={addSensorComment} deleteComment={deleteSensorComment} />
                                        </div>
                                    </PermissionsWrapper>

                                    <div className={`tab-pane ${(tabActive === 'maintenance')? 'active': ''}`} id="maintenance" role="tabpanel" aria-labelledby="home-tab-fill">
                                        <div className="card">
                                            <div className="card-header border-bottom">
                                                <h4 className="card-title">Wartung </h4>
                                            </div>
                                            <div className="card-body py-2 my-25">


                                            </div>
                                        </div>
                                    </div>

                                    <div className={`tab-pane ${(tabActive === 'administration')? 'active': ''}`} id="administration" role="tabpanel" aria-labelledby="home-tab-fill">
                                        <div className="card">
                                            <div className="card-header border-bottom">
                                                <h4 className="card-title">Administration </h4>
                                            </div>
                                            <div className="card-body py-2 my-25">


                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )

}

SensorDetails.requiredScope = ['sensor.view.details'];
SensorDetails.requireAuth = true;  // true -> force login via AuthContext, false -> no login required so no user available either
export default SensorDetails;