import React, { Fragment, useState, useEffect, useContext, useRef } from 'react';
import OrgCookieCrumb from "../../../../components/Dashboard/orgCookieCrumb";
import { uniqueId } from 'underscore';
import TblSensorAudit from '../../../../components/Dashboard/tables/dataTables/tblSensorAudit';
import AxiosClient from '../../../../utils/axios_client';


const SensorAudit = () => {

    const [SensorAuditData, setSensorAuditData] = useState([])
    const [updated, setUpdated] = useState(false)
    const [filters, setFilters] = useState({
        bad_rssi: {
            status: false,
            name: 'Schlechter RSSI'
        },
        invalid: {
            status: false,
            name: 'Validierungs-Fehler'
        },
        notUpdated: {
            status: false,
            name: 'Keine Kalkulation/Updates'
        },
        noMeasurements: {
            status: false,
            name: 'Keine Messwerte'
        },
        transmission_lost: {
            status: false,
            name: 'Kontakt verloren'
        },
        decryptionError: {
            status: false,
            name: 'Entschlüsselungs-Fehler'
        },
        nullMeasurement: {
            status: false,
            name: 'Messwert-Fehler'
        },
        errorReport: {
            status: false,
            name: 'Fehlerbericht'
        },
        handicap: {
            status: false,
            name: 'Messwert abnormal'
        },
        countsBackwards: {
            status: false,
            name: 'Falsch rum eingebaut'
        },
        notContinous: {
            status: false,
            name: 'Zählt nicht kontinuierlich'
        },

    })
    const [filteredData, setFilteredData] = useState([])

    useEffect(() => {
        if (!updated) {
            setUpdated(true)
            getSensorAuditData()
        }

    }, [filters, filteredData])

    const getSensorAuditData = async () => {
        try {
            const result = await AxiosClient.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/audit/sensor`)
            setSensorAuditData(result.data)
            setFilteredData(result.data)
        } catch (err) {
            console.log('error at getting sensor audit data', err);
        }
    }

    const updateFilter = (e) => {
        e.stopPropagation();
        const prev = { ...filters }
        prev[e.target.name].status = !prev[e.target.name].status
        setFilters(prev)
        updateFilteredData(prev)
    }

    const updateFilteredData = (newFilter) => {
        let filtered = [...SensorAuditData]
        if (newFilter.bad_rssi.status) {
            filtered = filtered.filter(snsr => snsr.stats.rssi !== "GOOD" && snsr.stats.rssi !== "GREAT")
        }
        if(newFilter.invalid.status) {
            filtered = filtered.filter(snsr => snsr.flags.invalid === true)
        }
        if(newFilter.notUpdated.status) {
            filtered = filtered.filter(snsr => snsr.flags.notUpdated === true)
        }
        if(newFilter.noMeasurements.status) {
            filtered = filtered.filter(snsr => snsr.flags.noMeasurements === true)
        }
        if(newFilter.transmission_lost.status) {
            filtered = filtered.filter(snsr => snsr.flags.transmissionLost === true)
        }
        if(newFilter.decryptionError.status) {
            filtered = filtered.filter(snsr => snsr.flags.decryptionError === true)
        }
        if(newFilter.nullMeasurement.status) {
            filtered = filtered.filter(snsr => snsr.flags.nullMeasurement === true)
        }
        if(newFilter.errorReport.status) {
            filtered = filtered.filter(snsr => snsr.flags.errorReport === true)
        }
        if(newFilter.handicap.status) {
            filtered = filtered.filter(snsr => snsr.flags.handicap === true)
        }
        if(newFilter.countsBackwards.status) {
            filtered = filtered.filter(snsr => snsr.flags.countsBackwards === true)
        }
        if(newFilter.notContinous.status) {
            filtered = filtered.filter(snsr => snsr.flags.notContinous === true)
        }
        setFilteredData(filtered)
    }

    const UI = () => {

        return (
            <Fragment>
                <div className="content-header row">
                    <div className="content-header-left col-md-9 col-12 mb-2">
                        <div className="row breadcrumbs-top">
                            <div className="col-12">
                                <h2 className="content-header-title float-start mb-0">Sensor-Monitoring</h2>
                                <div className="breadcrumb-wrapper">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a>Tools</a></li>
                                        <li className="breadcrumb-item active">Sensor-Monitoring</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <OrgCookieCrumb />
                </div>

                <div className="content-body" >

                    <section id="gatewayAudit">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h4>Sensor-Monitoring</h4>
                                        <button aria-haspopup="true" type="button" data-bs-toggle="dropdown" className="btnOverflowMenu btn btn-primary dropdown-toggle mt-50 mb-50 dropdown-toggle-split" >
                                            Sensor-Filter
                                        </button>
                                        <ul role="menu" tabindex="-1" className="dropdown-menu dropdown-menu-left" >
                                            {
                                                Object.keys(filters).map((filterName, i) => {
                                                    return (
                                                        <li key={uniqueId()}>
                                                            <label className="dropdown-item" for={filterName}>
                                                                <input className="form-check-input input-sm" type="checkbox" id={filterName} name={filterName} checked={filters[filterName].status === true} onChange={updateFilter} />
                                                                <span className="ms-50 me-50">{filters[filterName].name}</span>
                                                            </label>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                    <div className="card-body">
                                        <TblSensorAudit data={filteredData} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )
}


SensorAudit.requiredScope = ['sensor.update.any'];
SensorAudit.requireAuth = true;
export default SensorAudit;
