import React, { Fragment, useState, useEffect, useContext, useRef } from 'react';
import { AuthContext } from "../../../../context/Auth/AuthProvider";
import OrgCookieCrumb from "../../../../components/Dashboard/orgCookieCrumb";
import Swal from '@molline/sweetalert2';
import { NavLink, Link } from "react-router-dom";
import { useRouter } from "../../../../components/Shared/Router/Router";
import { OrganizationContext } from '../../../../context/Organization/Organization';

const OrganizationCreator = (props) => {
    const { user } = useContext(AuthContext);
    const [tabActive, setTabActive] = useState('gateway-details-vertical')
    const { organizations, setOrganizations, createOrganization, deleteOrganization, importGodesysData } = useContext(OrganizationContext);

    const router = useRouter()
    const initialForm = {
        name: "",
        owner: "",
        street: "",
        house_number: "",
        city: "",
        province: "",
        post_code: "",
        country: "Deutschland",
        email: "",
        phone: "",
        mobile: "",
        fax: "",
        url: "",
        realm: user.realm
    }
    const [formValues, setFormValues] = useState(initialForm)

    useEffect(() => {
    }, [organizations, formValues]);

    const updateForm = (key, value) => {
        setFormValues({ ...formValues, [key]: value })
    }

    const resetForm = () => {
        setFormValues(initialForm)
        document.getElementById('org-create-frm').reset()
    }

    const createNewOrganization = async () => {
        const doSave = await doYouReallyWantToSave()
        if (!doSave) {
            return
        }
        try {
            await createOrganization(formValues)
            successToast('Organisaton wurde erstellt')
            resetForm()
            router.push('/admin/organizations')
        } catch (e) {
            console.log('error at creating organization', e);
            errorToast(e)
        }
    }

    const doYouReallyWantToSave = () => {
        return new Promise(async (resolve) => {
            if (window.sessionStorage && !window.sessionStorage.getItem("yeswecan")) {
                const answer = await Swal.fire({
                    title: 'Sicher?',
                    input: 'checkbox',
                    text: 'Damit werden die Daten gespeichert',
                    inputPlaceholder: 'Nicht mehr fragen',
                    showCancelButton: true,
                    confirmButtonText: 'Ja',
                    cancelButtonText: "Nein",
                })
                if (!answer.isConfirmed)
                    return resolve(false);
                if (answer.value === 1) {
                    window.sessionStorage.setItem("yeswecan", true);
                    return resolve(true);
                }
            }
            return resolve(true);
        })
    }
    const errorToast = (err) => {
        Swal.fire({
            toast: true,
            position: 'bottom-end',
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: true,
            text: err,
            showConfirmButton: false,
            customClass: {
                popup: "bg-danger"
            }
        })
    }

    const successToast = (msg) => {
        Swal.fire({
            toast: true,
            position: 'bottom-end',
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: true,
            text: msg,
            showConfirmButton: false,
            customClass: {
                popup: "bg-success"
            }
        })
    }

    const godesysImport = async (e) => {
        e.preventDefault()
        Swal.fire({
            title: "Godesys-Kundennumemr eingeben",
            input: "text",
            showCancelButton: true,
            confirmButtonText: "Importieren",
            showLoaderOnConfirm: true,
            preConfirm: async (customer_number) => {
                try {
                    const result = await importGodesysData(customer_number)
                    return result
                } catch (err) {
                    errorToast('Fehler beim Abrufen der Daten aus Godesys')
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                let street = ""
                let house_number = ""
                if (!!result?.value?.strasse) {
                    let lastIndex = result?.value?.strasse.lastIndexOf(" ");
                    street = lastIndex === -1 ? result?.value?.strasse : result?.value?.strasse.substring(0, lastIndex);
                    house_number = result.value.strasse.substring(result.value.strasse.lastIndexOf(" ") + 1)
                }
                setFormValues({
                    name: (!!result?.value?.name1) ? result.value.name1 : '',
                    owner: (!!result?.value?.name1) ? result.value.name1 : '',
                    street: street,
                    house_number: house_number,
                    city: (!!result?.value?.ort) ? result.value.ort : '',
                    province: (!!result?.value?.Bundesland) ? result.value.Bundesland : '',
                    post_code: (!!result?.value?.plz) ? result.value.plz : '',
                    country: (!!result?.value?.LAND) ? result.value.LAND : '',
                    email: (!!result?.value?.emailadr) ? result.value.emailadr : '',
                    phone: undefined,
                    mobile: undefined,
                    fax: undefined,
                    url: undefined,
                    realm: user.realm
                })
            }
        });

    }

    const UI = () => {
        if (user === undefined) return <Fragment></Fragment>
        return (
            <Fragment>

                <div className="content-header row">
                    <div className="content-header-left col-md-9 col-12 mb-2">
                        <div className="row breadcrumbs-top">
                            <div className="col-12">
                                <h2 className="content-header-title float-start mb-0">Organisation Erstellen</h2>
                                <div className="breadcrumb-wrapper">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a >Apps</a>
                                        </li>
                                        <li className="breadcrumb-item"><Link to="/admin/organizations"><a >Organisationen</a></Link>
                                        </li>
                                        <li className="breadcrumb-item active">Organisation Erstellen
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <OrgCookieCrumb />
                </div>
                <div className="content-body">
                    <section className="vertical-wizard">
                        <div className="bs-stepper vertical vertical-wizard-example">
                            <div className="bs-stepper-header">

                                <div className={`step ${(tabActive === 'gateway-details-vertical') ? 'active' : ''}`} data-target="#gateway-details-vertical" role="tab" id="gateway-details-vertical-trigger" onClick={() => setTabActive('gateway-details-vertical')}>
                                    <button type="button" className="step-trigger">
                                        <span className="bs-stepper-box">1</span>
                                        <span className="bs-stepper-label">
                                            <span className="bs-stepper-title">Einzelregistierung</span>
                                            <span className="bs-stepper-subtitle">via Detaileingabe</span>
                                        </span>
                                    </button>
                                </div>

                            </div>
                            <div className="bs-stepper-content">
                                <form className='row' id="org-create-frm">
                                    <div className="mb-1 col-12">
                                        <button className='btn btn-primary' onClick={(e) => godesysImport(e)}>Godesys-Import</button>
                                    </div>
                                    <div className="mb-1 col-md-6">
                                        <label className="form-label">Name*</label>
                                        <input type="text" className="form-control" value={formValues.name} required onChange={(e) => updateForm('name', e.target.value)} />
                                    </div>
                                    <div className="mb-1 col-md-6">
                                        <label className="form-label">Besitzer*</label>
                                        <input type="text" className="form-control" value={formValues.owner} onChange={(e) => updateForm('owner', e.target.value)} />
                                    </div>
                                    <div className="mb-1 col-md-6">
                                        <label className="form-label">Straße*</label>
                                        <input type="text" className="form-control" value={formValues.street} onChange={(e) => updateForm('street', e.target.value)} />
                                    </div>
                                    <div className="mb-1 col-md-6">
                                        <label className="form-label">Hausnummer*</label>
                                        <input type="text" className="form-control" value={formValues.house_number} onChange={(e) => updateForm('house_number', e.target.value)} />
                                    </div>
                                    <div className="mb-1 col-md-6">
                                        <label className="form-label">Stadt*</label>
                                        <input type="text" className="form-control" value={formValues.city} onChange={(e) => updateForm('city', e.target.value)} />
                                    </div>
                                    <div className="mb-1 col-md-6">
                                        <label className="form-label">Bundesland*</label>
                                        <input type="text" className="form-control" value={formValues.province} onChange={(e) => updateForm('province', e.target.value)} />
                                    </div>
                                    <div className="mb-1 col-md-6">
                                        <label className="form-label">Poszleizahl*</label>
                                        <input type="text" className="form-control" value={formValues.post_code} onChange={(e) => updateForm('post_code', e.target.value)} />
                                    </div>
                                    <div className="mb-1 col-md-6">
                                        <label className="form-label">Land*</label>
                                        <input type="text" className="form-control" value={formValues.country} onChange={(e) => updateForm('country', e.target.value)} />
                                    </div>
                                    <div className="mb-1 col-md-6">
                                        <label className="form-label">Email*</label>
                                        <input type="emal" className="form-control" value={formValues.email} onChange={(e) => updateForm('email', e.target.value)} />
                                    </div>
                                    <div className="mb-1 col-md-6">
                                        <label className="form-label">Telefon*</label>
                                        <input type="text" className="form-control" value={formValues.phone} onChange={(e) => updateForm('phone', e.target.value)} />
                                    </div>
                                    <div className="mb-1 col-md-6">
                                        <label className="form-label">Mobil</label>
                                        <input type="text" className="form-control" value={formValues.mobile} onChange={(e) => updateForm('mobile', e.target.value)} />
                                    </div>
                                    <div className="mb-1 col-md-6">
                                        <label className="form-label">Fax</label>
                                        <input type="text" className="form-control" value={formValues.fax} onChange={(e) => updateForm('fax', e.target.value)} />
                                    </div>
                                    <div className="mb-1 col-md-6">
                                        <label className="form-label">Website</label>
                                        <input type="text" className="form-control" value={formValues.url} onChange={(e) => updateForm('url', e.target.value)} />
                                    </div>
                                    <div className="mb-1 col-md-6">
                                        <label className="form-label">Realm*</label>
                                        <input type="text" className="form-control" value={user.realm} disabled />
                                    </div>
                                </form>
                                <div className='row'>
                                    <div className='col-12'>
                                        <button className='btn btn-primary me-1' onClick={createNewOrganization}>Erstellen</button>
                                        <button className='btn btn-secondary' onClick={resetForm}>Zurücksetzen</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Fragment>
        )



    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )


}


OrganizationCreator.requiredScope = ['organization.create.any', 'organization.create.own']
OrganizationCreator.requireAuth = true;  // true -> force login via AuthContext, false -> no login required so no user available either
export default OrganizationCreator;